<template>
  <div class="myworkordertable-container">
    <div class="myworkordertable-nav">
      <div class="left">
        <span>全部<i>(95)</i></span>
      </div>
      <div class="right">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          v-model="input2"
          class="selectinput"
        >
        </el-input>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      class="myworkordertabletable"
      stripe
    >
      <!-- 序号 -->
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="80"
        fixed
      >
      </el-table-column>

      <!-- 业务类型 -->
      <el-table-column
        prop="business_type"
        align="center"
        label="业务类型"
      ></el-table-column>

      <!-- 公司名称 -->
      <el-table-column
        prop="corporate_name"
        align="center"
        label="公司名称"
      ></el-table-column>

      <!-- 发布人 -->
      <el-table-column prop="publisher" align="center" label="发布人">
      </el-table-column>
      <!-- 发布时间 -->
      <el-table-column prop="release_time" align="center" label="发布时间">
      </el-table-column>
      <!-- 到期时间 -->
      <el-table-column prop="expiration_time" align="center" label="到期时间">
      </el-table-column>
      <!-- 任务状态 -->
      <el-table-column prop="task_status" align="center" label="任务状态">
      </el-table-column>
      <!-- 任务详细 -->
      <el-table-column prop="task_details" align="center" label="任务详细">
      </el-table-column>
      <!-- 备注 -->
      <el-table-column prop="task_ps" align="center" label="备注">
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            type="button"
            class="confirmbtn"
            :disabled="scope.row.task_status == '进行中'"
            >完成</el-button
          >
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seebtn"
            >详情</el-button
          >
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seebtn"
            >驳回</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination-com></pagination-com>
    <myworkorderdetails-com
      :myorderdetailsVisible="myorderdetailsVisible"
      :rowdata="rowdata"
      @myorderdetailsVisibleClose="myorderdetailsVisibleClose"
    ></myworkorderdetails-com>
  </div>
</template>


<script>
import paginationCom from '../paginationCom.vue'
import myworkorderdetailsCom from './myworkorderdetailsCom.vue'
export default {
  components: {
    paginationCom, myworkorderdetailsCom
  },
  data() {

    return {
      value: '',
      myorderdetailsVisible: false,
      rowdata: {},
      input2: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      /**
            * 公司名称:corporate_name
            * 业务类型:business_type
            * 发布人:publisher
            * 发布时间:release_time
            * 到期时间:expiration_time
            * 任务状态:task_status
            * 任务详细:task_details
            * 备注:task_ps
            */
      //   最多7条数据可以展示在页面上  所以一页就是7条
      tableData: [{
        releaser_area: '百色区',
        receiver_area: '南宁青秀',
        corporate_name: '广西天灿人力资源有限公司',
        business_type: '大厅申报',
        publisher: '天小餐',
        receiver: '天璨璨',
        release_time: '2021-07-01 15:34:05',
        expiration_time: '2021-07-01 15:34:05',
        contacts: '灿灿天',
        contacts_phone: '16099996666',
        address: '广西西乡塘区科德西路7号嘉士通金广场8335号',
        customerservice_area: '青秀区',
        customerservice: '天灿灿 1906668888',
        handle_area: '青秀区',
        task_status: '已完成',
        task_details: '信息详细展示',
        task_ps: '备注展示'
      },
      {
        releaser_area: '崇左区',
        receiver_area: '南宁江南',
        corporate_name: '广西天灿人力资源有限公司',
        business_type: '大厅申报',
        publisher: '天小餐',
        receiver: '天璨璨',
        release_time: '2021-07-01 15:34:05',
        expiration_time: '2021-07-01 15:34:05',
        contacts: '灿灿天',
        contacts_phone: '16099996666',
        address: '广西西乡塘区科德西路7号嘉士通金广场8335号',
        customerservice_area: '青秀区',
        customerservice: '天灿灿 1906668888',
        handle_area: '青秀区',
        task_status: '进行中',
        task_details: '信息详细展示',
        task_ps: '备注展示'
      },
      ],
    }
  },
  methods: {
    handleClick(row) {
      this.myorderdetailsVisible = true;
      this.rowdata = row
    },
    myorderdetailsVisibleClose() {
      this.myorderdetailsVisible = false;
    }
  }
}
</script>


<style lang="scss">
.myworkordertable-container {
  background-color: #fff;
  padding-bottom: 50px;
  .myworkordertable-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px 10px 40px;
    background-color: $index-buttonPor-color;
    color: #ffffff;
    box-sizing: border-box;
    .selectinput {
      margin-left: 10px;
    }
    .right {
      display: flex;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .el-select {
        text-align: center;
        .el-input__icon {
          line-height: 30px;
        }
        .el-input__inner {
          text-align: center;
          background-color: $index-buttonPor-color;
          color: #fff;
        }
        .el-input__inner::placeholder,
        .el-select__caret {
          color: #fff;
        }
      }
    }
  }
  .el-table thead {
    color: #333333;
  }
  .myworkordertabletable {
    height: 900px;
    .confirmbtn {
      background-color: $index-blue-color;
      color: #fff;
    }
    .seebtn {
      background-color: $index-buttonPor-color;
      color: #fff;
    }
    .is-disabled {
      background-color: #cccccc;
    }
  }
}
</style>