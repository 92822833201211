<template>
  <div class="myworkorder-container">
    <el-tabs v-model="activeName" type="card" class="myworktabs">
      <el-tab-pane name="all">
        <span slot="label"
          ><i class="iconfont icon-quanbu"></i>全部
          <el-badge :value="95" class="myworkbadge"> </el-badge>
        </span>
        <div class="myworkall-container">
          <myworktabletable-com></myworktabletable-com>
        </div>
      </el-tab-pane>
      <el-tab-pane name="first">
        <span slot="label"
          ><i class="iconfont icon-dangqianrenwu"></i>当前工单
          <el-badge :value="50" class="myworkbadge"> </el-badge>
        </span>
        <div class="myworkall-container">
          <myworkordercurrenttable-com></myworkordercurrenttable-com>
        </div>
      </el-tab-pane>
      <el-tab-pane name="end">
        <span slot="label"
          ><i class="iconfont icon-yiwancheng"></i>已完成
          <el-badge :value="45" class="myworkbadge"> </el-badge>
        </span>
        <div class="myworkall-container">
          <myworkorderfinishedtable-com></myworkorderfinishedtable-com>
        </div>
      </el-tab-pane>
      <el-tab-pane name="count">
        <span slot="label"><i class="iconfont icon-tongji"></i>工单统计</span>
        工单统计
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import myworktabletableCom from '../../components/workordermanagement/myworkordertableCom.vue'
import myworkordercurrenttableCom from '../../components/workordermanagement/myworkorder-currenttableCom'
import myworkorderfinishedtableCom from '../../components/workordermanagement/myworkorder-finishedtableCom.vue'
export default {
  data() {
    return {
      activeName: 'all',
    }
  },
  components: {
    myworktabletableCom, myworkordercurrenttableCom, myworkorderfinishedtableCom
  }
}
</script>

<style lang="scss">
.myworkorder-container {
  background-color: #f2f2f2;
  padding: 30px 50px;
  height: 100%;
  box-sizing: border-box;
  .myworktabs {
    .myworkbadge {
      position: absolute;
      top: -10px;
      right: 10px;
    }
    .el-tabs__nav {
      border-radius: 10px;
    }
    // .el-tabs__header {
    //   margin: 0;
    //   border: none;
    // }
    // .el-tabs__item {
    //   border: none;
    //   height: 60px;
    //   line-height: 60px;
    //   padding: 0 40px;
    //   text-align: center;
    //   font-size: 18px;
    //   color: #9b9b9b;
    // }
    // .is-active {
    //   background-color: $index-buttonPor-color !important;
    //   color: #ffffff !important;
    // }
    .el-tabs__header {
      margin: 0 !important;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
      border: none;
    }
    .el-tabs--card > .el-tabs__header {
      border: 0;
    }
    .el-tabs__item {
      border: none;
      height: 60px;
      line-height: 60px;
      padding: 0 40px;
      text-align: center;
      font-size: 18px;
      color: #9b9b9b;
    }
    .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
      // padding-left: 40px;
    }
    .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
      // padding-right: 40px;
    }
    .is-active {
      background-color: $index-buttonPor-color !important;
      color: #ffffff !important;
    }
  }
  .iconfont {
    margin-right: 10px;
    font-size: 18px;
  }
}
</style>